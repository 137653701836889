import { Injectable } from '@angular/core';
import { ApiService } from '../services/api.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { LoadedSustainability, LoadSustainability } from './sustainability.reducer';
import { firstValueFrom, switchMap } from 'rxjs';
import { AppError } from './general.reducer';

@Injectable()
export class SustainabilityEffects {
  load$ = this.actions$.pipe(ofType(LoadSustainability));
  constructor(private actions$: Actions, private api: ApiService) {}
  onLoad$ = createEffect(() =>
    this.load$.pipe(
      switchMap(() =>
        firstValueFrom(this.api.getSustainability())
          .then((data) => LoadedSustainability({ data }))
          .catch((error) => AppError({ error }))
      )
    )
  );
}
