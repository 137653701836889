<ng-container *ngIf="data$ | async as sustainability">
  <ca-header [media]="sustainability.data?.banner"></ca-header>

  <ng-container *ngFor="let item of sustainability.data?.items; let i = index">
    <ng-container *ngIf="item.steps.length === 0">
      <div [class]="item.className">
        <div class="image-container" *ngIf="item.image">
          <img [src]="base + item.image.src" [alt]="item.image.alt" />
        </div>
        <div class="content">
          <h1>{{ item.title }}</h1>
          <div [outerHTML]="item.text"></div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="item.steps.length > 0">
      <ng-container *ngFor="let step of item.steps; let stepI = index">
        <div [class]="step.className">
          <h1 *ngIf="stepI === 0">{{ item.title }}</h1>
          <div *ngIf="step.startImage" class="image-container">
            <img [src]="base + step.startImage.src" [alt]="step.startImage.alt" />
          </div>
          <div class="content">
            <div class="step-title">
              <span
                class="step-number"
                [innerText]="stepI < 9 ? '0' + (stepI + 1) : stepI + 1"></span>
              <div>
                <h2>{{ step.title }}</h2>
              </div>
              <div>
                <h3>{{ step.subtitle }}</h3>
              </div>
            </div>
            <div [outerHTML]="step.text"></div>
          </div>
          <div *ngIf="step.endImage" class="image-container">
            <img [src]="base + step.endImage.src" [alt]="step.endImage.alt" />
          </div>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
