// import moment = require('moment'); // does not work in angular
import * as moment from 'moment'; // does not work in nestjs

function mapSpaces(str: string) {
  return str.replace(/\+/g, '%20');
}

export function parseWebhookBody<T>(bodyString: string): T {
  const fields = bodyString.split('&');
  const res: { [key: string]: string } = {};

  fields.forEach((field) => {
    const key = decodeURIComponent(mapSpaces(field.split('=')[0]));
    const value = decodeURIComponent(mapSpaces(field.split('=')[1]));
    res[key] = value;
  });

  return res as T;
}

export function mapDate(date?: string): string | undefined {
  // Logger.log('mapping date', date);
  if (date !== undefined && date.length > 0)
    return moment(date, 'M/D/YYYY hh:mm:ss a').toISOString();
  return undefined;
}

export const capitalizeWord = (word: string) => {
  if (!word) return word;
  return word[0].toUpperCase() + word.substring(1).toLocaleLowerCase();
};

export function parseCANameRegie(project_title: string, client_code: string) {
  return `${client_code}${moment().format('YY')}${parseSequenceNumber(1)}_${project_title}}`;
}

export function parseCAName(project_title: string, client_code: string, sequence_number = 1) {
  return `${client_code}${moment().format('YY')}${parseSequenceNumber(
    sequence_number
  )}_${project_title.replace(' ', '-')}`;
}

export function FormUniqueClientAbbreviation(
  clientName: string,
  existingClientAbbreviations: { ca_abbreviation: string }[],
  prefferedAbbreviation?: string
): string {
  const abbreviationsToAvoid = existingClientAbbreviations.map((abbr) => abbr.ca_abbreviation);
  let generatedName = convertToAlphaNumeric(prefferedAbbreviation ?? clientName)
    .trim()
    .slice(0, 3)
    .toLocaleUpperCase();
  let extractor = 1;
  while (abbreviationsToAvoid.includes(generatedName) && clientName.length > 1 + extractor) {
    const newBase = clientName.slice(0, 1) + clientName.slice(extractor, extractor + 2);
    console.log('retrying from base', newBase);
    generatedName = convertToAlphaNumeric(newBase).trim().slice(0, 3).toLocaleUpperCase();
    extractor++;
  }
  if (abbreviationsToAvoid.includes(generatedName))
    throw new Error('Could not generate Unique client abbreviation for ' + clientName);
  else return generatedName;
}

export const parseSlackChannelName = (name: string) =>
  convertToAlphaNumeric(name.replace(/\s+/g, '-').toLowerCase().trim());

export function mapUrlEncodedStringToJSON<T = unknown>(urlEncodedString?: string): T | null {
  if (urlEncodedString === undefined) return null;
  const decodedDataString = decodeURIComponent(urlEncodedString);
  const jsonString =
    '{"' + decodedDataString.replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}';
  try {
    return JSON.parse(jsonString) as T;
  } catch (err) {
    console.error('could not parse json\n' + jsonString);
    return null;
  }
}

export function parseSequenceNumber(num: number) {
  const numString = num.toString();
  return numString.length === 3
    ? numString
    : numString.length === 2
    ? '0' + numString
    : numString.length === 1
    ? '00' + numString
    : '001';
}

export function LooseMatch(a: string, b: string) {
  const valid = isStringAndNotEmpty(a) && isStringAndNotEmpty(b);
  if (valid) {
    return (
      convertToAlphaNumeric(a).toLocaleUpperCase().trim() ===
      convertToAlphaNumeric(b).toLocaleUpperCase().trim()
    );
  }
  return false;
}
export function convertToAlphaNumeric(input: string) {
  return input.replace(/[^0-9a-z]/gi, '');
}

export function TrimAndMatchCaseInsensitive(a: string, b: string) {
  return b.toLocaleUpperCase().trim() === b.toLocaleUpperCase().trim();
}
export function isEmptyString(v: string): boolean {
  return v.length === 0;
}
export function isStringAndNotEmpty(v?: string | null) {
  return v !== undefined && v !== null && typeof v === 'string' && v !== '';
}
export function Delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
export const isEmptyObject = (obj: any) => Object.keys(obj).length == 0;
/**
 * @description
 * Takes an Array<V>, and a grouping function,
 * and returns a Map of the array grouped by the grouping function.
 *
 * @param list An array of type V.
 * @param keyGetter A Function that takes the the Array type V as an input, and returns a value of type K.
 *                  K is generally intended to be a property key of V.
 *
 * @returns Map of the array grouped by the grouping function.
 */
export function GroupBy<K, V>(list: V[], keyGetter: (e: V) => K) {
  const map = new Map<K, Array<V>>();
  list.forEach((item) => {
    const key: K = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}
export function onlyUnique(value: any, index: any, self: any) {
  return self.indexOf(value) === index;
}
export function onlyUniqueIndexedNames(
  value: { id: number; name: string },
  index: number,
  self: { id: number; name: string }[]
) {
  return self.findIndex((e) => e.id === value.id) === index;
}
export function onlyUniqueTypeSafe<T>(value: T, index: number, array: T[]) {
  return array.indexOf(value) === index;
}
export const getRandomPin = (chars: string, len: number) =>
  [...Array(len)].map((i) => chars[Math.floor(Math.random() * chars.length)]).join('');
// export * from './file-utils';

/**
 * Get n random items from given array
 * @param array
 * @param n
 */
export function getRandomItemsFromArray<T>(array: T[], n = 5) {
  // Shuffle array
  const shuffled = shuffle([...array]); // array.sort(() => 0.5 - Math.random());

  // Get sub-array of first n elements after shuffled
  return shuffled.slice(0, n);
}

export function shuffle<T>(array: Array<T>) {
  let currentIndex = array.length;

  // While there remain elements to shuffle...
  while (currentIndex != 0) {
    // Pick a remaining element...
    const randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
  }
  return array;
}
