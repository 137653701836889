import { createAction, createReducer, on, props } from '@ngrx/store';
import { ContactsPageData, SEO } from '../models';
import { SetLanguage } from './general.reducer';

export interface ContactState {
  title: string;
  text: string;
  countries?: { name: string; code: string }[];
  seo: SEO;
}

export const initialState: ContactState = {
  title: 'Contacteer ons',
  text: '',
  seo: { description: 'Contacteer ons', title: 'Contact' },
};

export enum ContactActions {
  load = '[Contact Page Data] load',
  loaded = '[Contact Page Data] loaded',
  submitForm = '[Contact Form] submit',
  sentForm = '[Contact Form] sent',
  serverRejectsForm = '[Contact Form] rejected by server',
}

export const LoadContactData = createAction(ContactActions.load);

export const ContactDataLoaded = createAction(
  ContactActions.loaded,
  props<{
    data: ContactsPageData;
  }>()
);

export const SubmitForm = createAction(
  ContactActions.submitForm,
  props<{
    formData: FormData;
  }>()
);
export const SentForm = createAction(ContactActions.sentForm);
export const ServerRejectedForm = createAction(
  ContactActions.serverRejectsForm,
  props<{ error: any }>()
);
export const reducer = createReducer(
  initialState,
  on(ContactDataLoaded, (_, { data }) => ({
    ..._,
    title: data.title,
    countries: data.countries,
    seo: data.seo,
    text: data.text,
  })),
  on(SetLanguage, () => initialState)
);
