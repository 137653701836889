import { Component, OnDestroy, OnInit } from '@angular/core';
import { BruggeKaasState } from '../../store';
import { Store } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import { CaSubscriber } from '@ca/ca-utils';
import { LoadMoment, LoadMomentById } from '../../store/moments.reducer';
import { CheeseListItemData, MomentDetailPageData, RecipeListItemData } from '../../models';
import { selectCheesesAndRecipesForMoment, selectLanguage } from '../../store/selectors';
import { environment } from '../../../environments/environment';
import { LoadRecipes } from '../../store/recipes.reducer';
import { LoadCheesesListData } from '../../store/cheeses.reducer';
import { SeoService } from '../../services/seo.service';

@Component({
  selector: 'ca-moments-detail',
  templateUrl: './moment-detail.component.html',
})
export class MomentDetailComponent implements OnInit, OnDestroy {
  private sub = new CaSubscriber();
  base = environment.apiBaseUrl;
  cheeses: CheeseListItemData[] = [];
  recipes: RecipeListItemData[] = [];

  moment?: MomentDetailPageData['data'];
  moment$ = this.store.select((s) => s.moments.moment?.data);

  constructor(
    private seo: SeoService,
    private route: ActivatedRoute,
    private store: Store<BruggeKaasState>
  ) {
    this.sub.subscribe(this.store.select(selectLanguage), () => {
      if (this.moment) {
        this.store.dispatch(LoadMomentById({ id: this.moment.id }));
        this.store.dispatch(LoadRecipes());
        this.store.dispatch(LoadCheesesListData());
      }
    });

    this.sub.subscribe(this.moment$, (moment) => {
      this.moment = moment;
      this.seo.setMeta(moment);

      if (moment)
        this.sub.subscribe(
          this.store.select(selectCheesesAndRecipesForMoment(moment)),
          ({ cheeses, recipes }) => {
            this.cheeses = cheeses;
            this.recipes = recipes;
          }
        );
    });
  }

  ngOnInit(): void {
    const metaUrl = this.route.routeConfig?.path?.split('/')[1];
    if (metaUrl) {
      this.store.dispatch(LoadMoment({ metaUrl }));
      this.store.dispatch(LoadRecipes());
      this.store.dispatch(LoadCheesesListData());
    } else throw new Error('No metaUrl found in route');
  }

  ngOnDestroy(): void {
    this.sub.closeSubscriptions();
  }
}
