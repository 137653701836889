import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import * as fromGeneral from './general.reducer';
import * as fromContact from './contact.reducer';
import * as fromHome from './home.reducer';
import * as fromCheeses from './cheeses.reducer';
import * as fromLanding from './landing.reducer';
import * as fromMoments from './moments.reducer';
import * as fromPrivacy from './privacy-policy.reducer';
import * as fromDisclaimer from './disclaimer.reducer';
import * as fromRecipes from './recipes.reducer';
import * as fromSustainability from './sustainability.reducer';
import { PageDataBase } from '../models';

export interface BruggeKaasState {
  cheeses: fromCheeses.CheesesState;
  contact: fromContact.ContactState;
  disclaimer: PageDataBase;
  general: fromGeneral.GeneralState;
  home: fromHome.HomeState;
  landing: fromLanding.LandingState;
  moments: fromMoments.MomentsState;
  privacyPolicy: PageDataBase;
  recipes: fromRecipes.RecipesState;
  sustainability: fromSustainability.SustainabilityState;
}

export const ROOT_REDUCER: ActionReducerMap<BruggeKaasState> = {
  cheeses: fromCheeses.reducer,
  contact: fromContact.reducer,
  disclaimer: fromDisclaimer.reducer,
  general: fromGeneral.reducer,
  home: fromHome.reducer,
  landing: fromLanding.reducer,
  moments: fromMoments.reducer,
  privacyPolicy: fromPrivacy.reducer,
  recipes: fromRecipes.reducer,
  sustainability: fromSustainability.reducer,
};

export const metaReducers: MetaReducer<BruggeKaasState>[] = []; //hydrationMetaReducer
