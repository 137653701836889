import { createAction, createReducer, on, props } from '@ngrx/store';
import { LandingPageData } from '../models';
import { SetLanguage } from './general.reducer';

export interface LandingState {
  dismissedMedia: boolean;
  data?: LandingPageData;
}

export enum LandingActions {
  loaded = '[Landing] loaded',
  dismissLanding = '[Landing] dismiss',
  mediaDismissed = '[Landing] dismissed media',
}

export const LandingDataLoaded = createAction(
  LandingActions.loaded,
  props<{ data: LandingPageData }>()
);
export const DismissLanding = createAction(LandingActions.dismissLanding);

export const initialState: LandingState = {
  dismissedMedia: false,
};

export const reducer = createReducer(
  initialState,
  on(LandingDataLoaded, (_, { data }) => ({
    ..._,
    data,
  })),
  on(DismissLanding, (_) => ({
    dismissedMedia: true,
  })),
  on(SetLanguage, () => initialState)
);
