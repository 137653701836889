import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { BruggeKaasState } from '../../store';
import { CaSubscriber } from '@ca/ca-utils';
import { selectLanguage, selectSustainabilityPageData } from '../../store/selectors';
import { LoadSustainability } from '../../store/sustainability.reducer';
import { environment } from '../../../environments/environment';
import { Meta } from '@angular/platform-browser';
import { SeoService } from '../../services/seo.service';

@Component({
  selector: 'ca-sustainability',
  templateUrl: './sustainability.component.html',
})
export class SustainabilityComponent implements OnDestroy {
  protected base = environment.apiBaseUrl;
  private sub = new CaSubscriber();
  data$ = this.store.select(selectSustainabilityPageData);
  constructor(private store: Store<BruggeKaasState>, private seo: SeoService) {
    this.sub.subscribe(this.store.select(selectLanguage), () =>
      this.store.dispatch(LoadSustainability())
    );
    this.sub.subscribe(this.data$, (d) => this.seo.setMeta(d.data));
  }

  ngOnDestroy() {
    this.sub.closeSubscriptions();
  }
}
