import { Component, OnDestroy } from '@angular/core';
import { selectLanguage, selectPrivacyPolicyState } from '../../store/selectors';
import { LoadPrivacyPolicy } from '../../store/privacy-policy.reducer';
import { Store } from '@ngrx/store';
import { CaSubscriber } from '@ca/ca-utils';
import { GeneralState } from '../../store/general.reducer';
import { SeoService } from '../../services/seo.service';
import { PageDataBase } from '../../models';

@Component({
  selector: 'ca-privacy-and-cookies',
  template: `
    <ng-container *ngIf="data$ | async as page">
      <ca-header [media]="page.banner"></ca-header>
      <h1 *ngIf="page.title" class="privacy-title">{{ page.title }}</h1>
      <div *ngIf="page.text" [innerHTML]="page.text" class="privacy-content"></div>
    </ng-container>
  `,
})
export class PrivacyAndCookiesComponent implements OnDestroy {
  private sub = new CaSubscriber();
  data$ = this.store.select(selectPrivacyPolicyState);

  constructor(
    private seo: SeoService,
    private store: Store<{ general: GeneralState; privacyPolicy: PageDataBase }>
  ) {
    this.sub.subscribe(this.store.select(selectLanguage), () =>
      this.store.dispatch(LoadPrivacyPolicy())
    );
    this.sub.subscribe(this.data$, (d) => this.seo.setMeta(d));
  }
  ngOnDestroy(): void {
    this.sub.closeSubscriptions();
  }
}
