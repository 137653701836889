import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { LoadedMoment, LoadMoment, LoadMomentById } from './moments.reducer';
import { ApiService } from '../services/api.service';
import { firstValueFrom, switchMap } from 'rxjs';
import { AppError } from './general.reducer';

@Injectable()
export class MomentsEffects {
  load$ = this.actions$.pipe(ofType(LoadMoment));
  loadById$ = this.actions$.pipe(ofType(LoadMomentById));
  constructor(private actions$: Actions, private api: ApiService) {}
  onLoad$ = createEffect(() =>
    this.load$.pipe(
      switchMap(({ metaUrl }) =>
        firstValueFrom(this.api.getMoment(metaUrl))
          .then((moment) => LoadedMoment({ moment }))
          .catch((error) => AppError({ error }))
      )
    )
  );
  onLoadById$ = createEffect(() =>
    this.loadById$.pipe(
      switchMap(({ id }) =>
        firstValueFrom(this.api.getMoment(id))
          .then((moment) => LoadedMoment({ moment }))
          .catch((error) => AppError({ error }))
      )
    )
  );
}
