import { createAction, createReducer, on, props } from '@ngrx/store';
import { CheeseDetailPageData, CheeseListItemData, CheesesListPageData } from '../models';
import { SetLanguage } from './general.reducer';

export interface CheesesState {
  all: CheeseListItemData[];
  filtered: CheeseListItemData[];
  detail?: CheeseDetailPageData;
  listPageData?: Pick<CheesesListPageData, 'banner' | 'id' | 'seo' | 'text' | 'title'>;
}

export const initialState: CheesesState = {
  all: [],
  filtered: [],
};
//#region Actions
export enum CheeseActions {
  load = '[Cheeses List] load',
  loaded = '[Cheeses List] loaded',
  filter = '[Cheeses List] filter',
  loadDetail = '[Cheese Detail] load',
  loadDetailSuccess = '[Cheese Detail] loaded',
}

export const LoadCheesesListData = createAction(CheeseActions.load);
export const LoadedCheesesListData = createAction(
  CheeseActions.loaded,
  props<{ data: CheesesListPageData }>()
);

export const FilterCheeses = createAction(CheeseActions.filter, props<{ moment: number | null }>());

export const LoadCheeseDetail = createAction(
  CheeseActions.loadDetail,
  props<{ metaUrl: string | number }>()
);
export const LoadCheeseDetailSuccess = createAction(
  CheeseActions.loadDetailSuccess,
  props<{ data: CheeseDetailPageData }>()
);
//#endregion

export const reducer = createReducer(
  initialState,
  on(LoadedCheesesListData, (_, { data }) => ({
    ..._,
    all: data.cheeses.filter((c) => c.name !== undefined && c.name !== ''),
    filtered: data.cheeses.filter((c) => c.name !== undefined && c.name !== ''),
    listPageData: {
      id: data.id,
      seo: data.seo,
      text: data.text,
      title: data.title,
      banner: data.banner,
    },
  })),
  on(FilterCheeses, (_, { moment }) => ({
    ..._,
    filtered:
      moment !== null
        ? _.all.filter(
            (c) => c.moments.findIndex((m) => moment.toString() === m.id.toString()) !== -1
          )
        : _.all,
  })),
  on(LoadCheeseDetailSuccess, (_, { data }) => ({ ..._, detail: data })),
  on(SetLanguage, () => initialState)
);
