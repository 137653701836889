import { createAction, createReducer, on, props } from '@ngrx/store';
import { MomentData, MomentDetailPageData } from '../models';
import { HomeDataLoaded } from './home.reducer';
import { SetLanguage } from './general.reducer';

export interface MomentsState {
  moments: MomentData[];
  moment?: MomentDetailPageData;
}

export const initialState: MomentsState = { moments: [] };

export enum MomentsActions {
  load = '[Moment] load',
  loaded = '[Moment] loaded',
  loadById = '[Moment] load by id',
}

export const LoadMoment = createAction(MomentsActions.load, props<{ metaUrl: string }>());
export const LoadedMoment = createAction(
  MomentsActions.loaded,
  props<{ moment: MomentDetailPageData }>()
);

export const LoadMomentById = createAction(MomentsActions.loadById, props<{ id: number }>());

export const reducer = createReducer(
  initialState,
  on(HomeDataLoaded, (_, { data }) => ({
    ..._,
    moments: data.moments,
  })),
  on(LoadedMoment, (_, { moment }) => ({ ..._, moment })),
  on(SetLanguage, () => initialState)
);
