import { Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { CaSubscriber } from '@ca/ca-utils';
import { selectContactPageData, selectCountryHint, selectLanguage } from '../../store/selectors';
import { BruggeKaasState } from '../../store';
import { environment } from '../../../environments/environment';
import { LoadContactData, SentForm, SubmitForm } from '../../store/contact.reducer';

import {
  IsAlpha,
  IsEmail,
  IsOptional,
  IsString,
  MaxLength,
  MinLength,
  validate,
  ValidationError,
} from 'class-validator';
import { plainToInstance } from 'class-transformer';
import { Actions, ofType } from '@ngrx/effects';
import { Meta } from '@angular/platform-browser';
import { SeoService } from '../../services/seo.service';

const DEFAULT_ERRORS: FormErrors = {
  first_name: null,
  last_name: null,
  email: null,
  tel: null,
  street: null,
  postal_code: null,
  street_nr: null,
  city: null,
  country: null,
  subject: null,
  message: null,
  files: null,
};
const DEFAULT_FORM_VALUE = {
  first_name: null,
  last_name: null,
  email: null,
  tel: null,
  street: null,
  postal_code: null,
  street_nr: null,
  city: null,
  country: null,
  subject: null,
  message: null,
  files: null,
};

@Component({
  selector: 'ca-contact',
  templateUrl: './contact.component.html',
})
export class ContactComponent implements OnDestroy {
  @ViewChild('contactForm') set contactForm(content: ElementRef<HTMLFormElement>) {
    this._contactForm = content;
  }
  private _contactForm?: ElementRef<HTMLFormElement>;

  selectCountryHint$ = this.store.select(selectCountryHint);
  base = environment.apiBaseUrl;
  countries?: { name: string; code: string }[];
  labels?: Translations;

  data$ = this.store.select(selectContactPageData);
  private sub = new CaSubscriber();
  submitted = false;
  _errors: FormErrors = { ...DEFAULT_ERRORS };
  hasErrors = true;
  form: FormValue = { ...DEFAULT_FORM_VALUE };
  errorResult: ValidationError[] = [];
  max_mb = 60;

  files: File[] = [];

  fieldError(field: keyof ContactFormValue): string | null {
    const errors = this.errorResult.find((e) => e.property === field && e.constraints);
    if (!errors || !errors.constraints || !this.labels) return null;
    const keys: string[] = Object.keys(errors.constraints);

    if (keys.includes('isString')) return this.labels.isString ?? null;
    if (keys.includes('isAlpha')) return this.labels.isAlpha ?? null;
    if (keys.includes('isEmail')) return this.labels.isEmail ?? null;
    if (keys.includes('minLength')) return this.labels.minLength ?? null;
    if (keys.includes('maxLength')) return this.labels.maxLength ?? null;
    return null;
  }

  constructor(
    private store: Store<BruggeKaasState>,
    private actions$: Actions,
    private seo: SeoService
  ) {
    this.sub.subscribe(this.store.select(selectLanguage), () =>
      this.store.dispatch(LoadContactData())
    );
    this.sub.subscribe(this.data$, (d) => {
      this.seo.setMeta(d);
      if (d.countries)
        this.countries = [...d.countries].sort((a, b) => a.name.localeCompare(b.name));
      if (d.labels) this.labels = d.labels;
    });
    this.sub.subscribe(this.actions$.pipe(ofType(SentForm)), () => {
      this.reset();
    });
  }

  ngOnDestroy(): void {
    this.sub.closeSubscriptions();
  }

  reset() {
    this.submitted = false;
    this.form = { ...DEFAULT_FORM_VALUE };
    this._errors = { ...DEFAULT_ERRORS };
    this.hasErrors = true;
    this.errorResult = [];
    this.files = [];
  }

  filesChange(event: any) {
    // this.files = [];
    const fileList: FileList = event.target.files;
    if (fileList && fileList.length) {
      let total_size = 0;
      for (let file = 0; file < fileList.length; file++) {
        this.files.push(fileList[file]);
        total_size += fileList[file].size;
      }
      // console.log('total size', total_size, total_size / 1024 / 1024);
      if (total_size / 1024 / 1024 > this.max_mb) {
        this._errors.files = this.labels?.fileSizeError ?? null;
        this.hasErrors = true;
      }
    }
  }

  async validate() {
    if (this.submitted) {
      const v = plainToInstance(ContactFormValue, this.form);
      return validate(v)
        .then((res) => {
          if (res.length === 0) {
            this._errors = { ...DEFAULT_ERRORS };
            this.errorResult = [];
            this.hasErrors = false;
          } else {
            this.errorResult = res;
            // empty error object
            this._errors = { ...DEFAULT_ERRORS };
            // map errors to object
            Object.keys(this.form).forEach((k) => {
              const error = this.fieldError(k as keyof FormValue);
              this._errors[k as keyof FormErrors] = error;
            });
            this.hasErrors = true;
          }
          return !this.hasErrors;
        })
        .catch((err) => {
          console.error('not valid', err);
          return true;
        });
    }
    return false;
  }

  submit() {
    this.submitted = true;
    this.validate().then((res: boolean) => {
      if (res) {
        const formData: FormData = new FormData(
          this._contactForm?.nativeElement as HTMLFormElement
        );
        this.store.dispatch(SubmitForm({ formData }));
      }
    });
  }

  /**
   * Method to call when the File Input changes
   * @param obj The object the files are being modified from
   * @param inputEle
   */
  onFileChange(event: any) {
    const fileList: FileList = event.target.files;
    this.mapFiles(fileList);
  }

  /**
   * Maps the local myFiles file names from the current File Input value
   * @param obj The object to modify the file names of
   * @param files The FileList derived from the HTMLInputElement.files
   */
  private mapFiles(files: FileList) {
    // const fileNames: string[] = [];
    this.files = [];
    if (files && files.length) {
      for (let i = 0; i < files.length; i++) {
        this.files.push(files[i]);
      }
    }

    // obj.files = this.files;
    // obj.fileNames = fileNames;
  }

  /**
   * Removes a file from the File Input
   * @param obj The object to modify the file array of
   * @param fileName the name of the file to search for
   * @param inputEle the template referenced File Input
   */
  removeFile(fileName: string, inputEle: HTMLInputElement) {
    /**
     * The object to assign the new desired file list to,
     * as the FileList of the File Input is readonly
     */
    const dt = new DataTransfer();

    // Ensure there are any files to search beforehand
    if (inputEle.files && inputEle.files.length) {
      /**
       * Convert the FileList to a standard Array for ease
       */
      const fileArr = Array.from(inputEle.files);

      /**
       * The index of the file we need to remove, findIndex returns -1 if not found
       */
      const index = fileArr.findIndex((f) => f.name === fileName);

      // Remove the file at the given index, if found
      if (index !== -1) {
        fileArr.splice(index, 1);
      }

      // After the unwanted file has been removed, add the remaining files to the DataTransfer.tiems
      for (const file of fileArr) {
        dt.items.add(file);
      }
    }

    // Assign the File Input files value to the DataTransfer.files value
    inputEle.files = dt.files;

    // Re-map the string file names for the UI
    this.mapFiles(inputEle.files);
  }
}

export class ContactFormValue {
  @IsString()
  @MinLength(2)
  @MaxLength(125)
  first_name: string | null = null;

  @IsString()
  @MinLength(2)
  @MaxLength(125)
  last_name: string | null = null;

  @IsString()
  @IsEmail()
  @MaxLength(255)
  email: string | null = null;

  @IsOptional()
  @IsString()
  @MinLength(2)
  @MaxLength(25)
  tel: string | null = null;

  @IsOptional()
  @IsString()
  @MinLength(2)
  @MaxLength(255)
  street: string | null = null;

  @IsOptional()
  @IsString()
  @MinLength(2)
  @MaxLength(25)
  street_nr: string | null = null;

  @IsOptional()
  @IsString()
  @MinLength(4)
  @MaxLength(10)
  postal_code: string | null = null;

  @IsOptional()
  @IsString()
  @MinLength(2)
  @MaxLength(125)
  city: string | null = null;

  @IsString()
  @MinLength(2)
  @MaxLength(2)
  @IsAlpha()
  country: string | null = null;

  @IsString()
  @MinLength(2)
  @MaxLength(255)
  subject: string | null = null;

  @IsString()
  @MinLength(5)
  @MaxLength(65535)
  message: string | null = null;

  @IsOptional()
  files: FileList | null = null;
}

export type ValidationErrorType = 'isString' | 'isEmail' | 'minLength' | 'maxLength';

export interface ContactFormError {
  property: keyof ContactFormValue;
  label: string;
  errors: { type: ValidationErrorType; error: string }[];
}

export type FormErrors = {
  first_name: string | null;
  last_name: string | null;
  email: string | null;
  tel: string | null;
  street: string | null;
  postal_code: string | null;
  street_nr: string | null;
  city: string | null;
  country: string | null;
  subject: string | null;
  message: string | null;
  files: string | null;
};
// export type FormErrors = {
//   first_name: ValidationErrorType | null;
//   last_name: ValidationErrorType | null;
//   email: ValidationErrorType | null;
//   tel: ValidationErrorType | null;
//   street: ValidationErrorType | null;
//   postal_code: ValidationErrorType | null;
//   street_nr: ValidationErrorType | null;
//   city: ValidationErrorType | null;
//   country: ValidationErrorType | null;
//   subject: ValidationErrorType | null;
//   message: ValidationErrorType | null;
//   files: ValidationErrorType | null;
// };

type FormValue = {
  first_name: string | null;
  last_name: string | null;
  email: string | null;
  tel: string | null;
  street: string | null;
  postal_code: string | null;
  street_nr: string | null;
  city: string | null;
  country: string | null;
  subject: string | null;
  message: string | null;
  files: FileList | null;
};
type Translations = {
  isAlpha: any;
  first_name?: string;
  last_name?: string;
  email?: string;
  phone?: string;
  street?: string;
  street_number?: string;
  postal_code?: string;
  city?: string;
  country?: string;
  subject?: string;
  message?: string;
  attachment?: string;
  hintAttachment?: string;
  send?: string;
  isEmail?: string;
  isString?: string;
  minLength?: string;
  maxLength?: string;
  lblFirstName?: string;
  lblLastName?: string;
  lblEmail?: string;
  lblPhone?: string;
  lblAddressStreet?: string;
  lblAddressNumber?: string;
  lblAddressPostalCode?: string;
  lblAddressCity?: string;
  lblAddressCountry?: string;
  lblSubject?: string;
  subjectOptionProducts?: string;
  subjectOptionSponsoring?: string;
  subjectOptionPress?: string;
  subjectOptionJobs?: string;
  subjectOptionComplaints?: string;
  subjectOptionOther?: string;
  lblMessage?: string;
  lblAttachment?: string;
  btnUpload?: string;
  btnSend?: string;
  relatedRecipes?: string;
  cheeseFilterLabel?: string;
  momentFilterLabel?: string;
  formSuccessMessage?: string;
  formErrorMessage?: string;
  fileSizeError?: string;
  maxLengthError?: string;
  minLengthError?: string;
  isAlphaError?: string;
  isEmailError?: string;
  isStringError?: string;
};
