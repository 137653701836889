import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input, Output, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { SupportedLanguage } from '../../models';
import { ApiService } from '../../services/api.service';
import { GeneralState, SetLanguage } from '../../store/general.reducer';
import { CaSubscriber } from '@ca/ca-utils';
import { Store } from '@ngrx/store';
import {
  selectLanguage,
  selectPrimaryMenuItems,
  selectSecondaryMenuItems,
} from '../../store/selectors';

@Component({
  selector: 'ca-menu',
  template: `
    <ng-container *ngIf="open">
      <div @openClose class="menu">
        <nav>
          <a
            *ngFor="let route of primaryLinks$ | async"
            class="page-link"
            [routerLink]="['/' + route.metaUrl]"
            routerLinkActive="active"
            [class.extra-link]="route.id === 11 || route.id === 12"
            >{{ route.menuName }}</a
          >
          <a
            *ngFor="let route of secondaryLinks$ | async"
            class="page-link"
            [routerLink]="['/' + route.metaUrl]"
            routerLinkActive="active"
            [class.extra-link]="route.id === 11 || route.id === 12"
            >{{ route.menuName }}</a
          >
        </nav>

        <div class="languages">
          <a
            *ngFor="let l of languages"
            (click)="changeLanguage(l)"
            [class.active]="l === language"
            >{{ l }}</a
          >
        </div>
        <a class="material-symbols-outlined close-icon" (click)="dismissed.emit()"></a>
      </div>
    </ng-container>
  `,
  animations: [
    trigger('openClose', [
      transition(':enter', [
        style({
          // opacity: 0,
          bottom: '100vh',
        }),
        animate(
          '.5s',
          style({
            // opacity: 1,
            bottom: '0vh',
          })
        ),
      ]),
      transition(':leave', [
        style({
          bottom: '00vh',
        }),
        animate(
          '.5s',
          style({
            bottom: '100vh',
          })
        ),
      ]),
    ]),
  ],
})
export class MenuComponent implements OnInit, OnDestroy {
  @Input() open = false;
  @Output() dismissed = new EventEmitter();
  language?: SupportedLanguage;
  languages: SupportedLanguage[] = ['en', 'nl', 'fr', 'es'];

  primaryLinks$ = this.store.select(selectPrimaryMenuItems);
  secondaryLinks$ = this.store.select(selectSecondaryMenuItems);

  private sub = new CaSubscriber();

  constructor(protected api: ApiService, private store: Store<{ general: GeneralState }>) {}
  ngOnInit(): void {
    this.sub.subscribe(this.store.select(selectLanguage), {
      next: (lang) => (this.language = lang),
    });
  }

  ngOnDestroy(): void {
    this.sub.closeSubscriptions();
  }

  changeLanguage(language: SupportedLanguage) {
    // dispatch action
    this.store.dispatch(SetLanguage({ language }));
  }
}
