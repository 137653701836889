<ng-container *ngIf="data$ | async as page; else loader">
  <ca-header [title]="page.title" [media]="page.banner"></ca-header>
  <div [outerHTML]="page.text"></div>
  <div class="filters">
    <div class="select">
      <select id="standard-select" [(ngModel)]="cheeseFilter" *ngIf="all$ | async as cheeses">
        <option [selected]="cheeseFilter === null" [value]="null">
          {{ cheesesSelectHint$ | async }}
        </option>
        <option *ngFor="let cheese of cheeses" [routerLink]="cheese.routerLink">
          {{ cheese.name }}
        </option>
      </select>
    </div>

    <div class="select" *ngIf="momentFilters$ | async as moments">
      <select
        id="standard-select"
        [(ngModel)]="momentFilter"
        (ngModelChange)="filterChange($event)">
        <option [selected]="momentFilter === null" [value]="null">
          {{ momentSelectHint$ | async }}
        </option>
        <option *ngFor="let moment of moments" [value]="moment.id">{{ moment.name }}</option>
      </select>
    </div>
  </div>

  <div class="cheeses-list" *ngIf="filtered$ | async as cheeses">
    <a *ngFor="let cheese of cheeses" [routerLink]="cheese.routerLink" class="cheeses-list-item">
      <img [class]="cheese.shape" [alt]="cheese.image.alt" [src]="base + cheese.image.src" />
      <div class="info">
        <h3>{{ cheese.name }}</h3>
        <ng-container *ngIf="cheese.moments">
          <ng-container *ngFor="let m of cheese.moments">
            <span *ngIf="m.name !== ''"> #{{ m.name + ' ' }} </span>
          </ng-container>
        </ng-container>
      </div>
    </a>
  </div>
</ng-container>
<ng-template #loader>
  <!-- Insert template here -->
</ng-template>
