import { AfterViewInit, Component, Inject, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { CaSubscriber } from '@ca/ca-utils';
import { Store } from '@ngrx/store';
import { Observable, filter, firstValueFrom } from 'rxjs';
import { AppError, GeneralState, SetURL } from './store/general.reducer';
import { selectLanguage, selectLinks, selectShortcutData } from './store/selectors';
import { LoadRecipes } from './store/recipes.reducer';
import * as CookieConsent from 'vanilla-cookieconsent';
import { PageID, SupportedLanguage } from './models';
import { CookieConsentConfig } from './cookies.config';
import { Actions, ofType } from '@ngrx/effects';
import { DOCUMENT } from '@angular/common';
import { LoadCheesesListData } from './store/cheeses.reducer';

@Component({
  selector: 'ca-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit, OnDestroy {
  private sub = new CaSubscriber();
  protected menuOpen = false;

  navigationEnd$ = this.router.events.pipe(
    filter((e) => e instanceof NavigationEnd)
  ) as Observable<NavigationEnd>;

  notLanding = true;
  instagram?: string;
  facebook?: string;
  cheesesRouterLink?: string[];

  language: SupportedLanguage = 'nl';

  constructor(
    private actions$: Actions,
    private router: Router,
    private store: Store<{ general: GeneralState }>,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.store.dispatch(LoadRecipes());
    this.store.dispatch(LoadCheesesListData());

    this.sub.subscribe(this.actions$.pipe(ofType(AppError)), async (error) => {
      if (error.error.status === 404) {
        const defaultRoute = (await firstValueFrom(this.store.select(selectLinks)))?.find(
          (l) => l.id === PageID.HOME
        );
        this.router.navigate([defaultRoute ? defaultRoute.metaUrl : '/']);
      }
    });

    this.sub.subscribe(this.navigationEnd$, {
      next: (e: NavigationEnd) => {
        this.notLanding = e.urlAfterRedirects !== '/';
        this.store.dispatch(SetURL({ url: e.urlAfterRedirects }));
        // close menu
        this.menuOpen = false;
        // set current active page in store
        scrollTo(0, 0);
      },
    });

    this.sub.subscribe(this.store.select(selectShortcutData), {
      next: (d) => {
        this.cheesesRouterLink = d.cheesesLink;
        this.instagram = d.instagram;
        this.facebook = d.facebook;
      },
    });

    this.sub.subscribe(this.store.select(selectLanguage), (lang) => {
      this.language = lang;
      this.menuOpen = false;
      this.document.getElementsByTagName('html')[0].setAttribute('lang', lang);
    });
  }

  ngAfterViewInit(): void {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'auto',
    });

    const config = { ...CookieConsentConfig };
    config.language.default = this.language;
    CookieConsent.run(config);
  }

  ngOnDestroy(): void {
    this.sub.closeSubscriptions();
  }

  toggleMenu(open?: boolean) {
    this.menuOpen = open !== undefined ? open : !this.menuOpen;
  }
}
