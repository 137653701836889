import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ContactDataLoaded, LoadContactData, SentForm, SubmitForm } from './contact.reducer';
import { firstValueFrom, switchMap } from 'rxjs';
import { ApiService } from '../services/api.service';
import { AppError } from './general.reducer';

@Injectable()
export class ContactEffects {
  load$ = this.actions$.pipe(ofType(LoadContactData));
  submit$ = this.actions$.pipe(ofType(SubmitForm));
  constructor(private actions$: Actions, private api: ApiService) {}
  onLoad$ = createEffect(() =>
    this.load$.pipe(
      switchMap(() =>
        firstValueFrom(this.api.getContact())
          .then((data) => ContactDataLoaded({ data }))
          .catch((error) => AppError({ error }))
      )
    )
  );
  onSubmit$ = createEffect(() =>
    this.submit$.pipe(
      switchMap(({ formData }) =>
        this.api
          .postContactForm(formData)
          .then(() => SentForm())
          .catch((error) => AppError({ error }))
      )
    )
  );
}
