import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { environment } from '../../environments/environment';
import {
  CheesesListPageData,
  ContactsPageData,
  General,
  HomePageData,
  LandingPageData,
  MomentDetailPageData,
  PageID,
  RecipeDetailPageData,
  RecipesListPageData,
  SupportedLanguage,
  SustainabilityPageData,
} from '../models';
import { firstValueFrom, Observable } from 'rxjs';
import { DateTime } from 'luxon';
import { Store } from '@ngrx/store';
import { GeneralState } from '../store/general.reducer';
import { CaSubscriber } from '@ca/ca-utils';
import { selectLanguage, selectRegion } from '../store/selectors';

@Injectable({
  providedIn: 'root',
})
export class ApiService implements OnDestroy {
  private base = environment.apiBaseUrl;

  private sub = new CaSubscriber();

  language?: SupportedLanguage;
  region: 'US' | 'world' = 'world';

  constructor(private http: HttpClient, private store: Store<{ general: GeneralState }>) {
    this.sub.subscribe(this.store.select(selectLanguage), {
      next: (lang) => {
        this.language = lang;
      },
    });
    this.sub.subscribe(this.store.select(selectRegion), {
      next: (region) => {
        this.region = region;
      },
    });
  }
  ngOnDestroy(): void {
    this.sub.closeSubscriptions();
  }

  getGeneral(): Observable<General> {
    const timestamp = DateTime.now().toUnixInteger();
    return this.http.get<General>(
      `${this.base}/${this.language}/api/website/general?timestamp=${timestamp}&region=${this.region}`
    );
  }

  getPage<T>(pageId: PageID): Observable<T> {
    return this.http.get<T>(
      `${this.base}/${
        this.language
      }/api/website/page/${pageId}?timestamp=${getTimestamp()}&region=${this.region}`
    );
  }

  getDetail<T>(pageId: PageID, metaUrl: number | string) {
    return this.http.get<T>(
      `${this.base}/${
        this.language
      }/api/website/page/${pageId}/detail/${metaUrl}?timestamp=${getTimestamp()}&region=${
        this.region
      }`
    );
  }

  getLanding = () => this.getPage<LandingPageData>(PageID.LANDING);
  getHome = () => this.getPage<HomePageData>(PageID.HOME);
  getContact = () => this.getPage<ContactsPageData>(PageID.CONTACT);
  getCheeses = () => this.getPage<CheesesListPageData>(PageID.CHEESES);
  getMoment = (metaUrl: string | number) =>
    this.getDetail<MomentDetailPageData>(PageID.MOMENTS, metaUrl);
  getRecipes = () => this.getPage<RecipesListPageData>(PageID.RECIPES);
  getRecipe = (metaUrl: string | number) =>
    this.getDetail<RecipeDetailPageData>(PageID.RECIPES, metaUrl);
  getSustainability = () => this.getPage<SustainabilityPageData>(PageID.SUSTAINABILITY);

  postContactForm(formData: FormData): Promise<any> {
    return firstValueFrom(this.http.post(`${this.base}/api/forms/contact`, formData));
  }
}

export function getTimestamp() {
  const time = DateTime.now();
  const timestamp = time.toUnixInteger();
  return timestamp;
}
