export type CaProjectType =
  | 'online_display'
  | 'print'
  | 'corporate_identity'
  | 'website'
  | 'webapp'
  | 'marketing_campaign'
  | 'event'
  | 'full_service'
  | 'intern';

export type Locale = 'nl' | 'en' | 'fr';
