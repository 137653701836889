import { Injectable } from '@angular/core';
import { ApiService } from '../services/api.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { firstValueFrom, switchMap } from 'rxjs';
import { LoadDisclaimer, LoadedDisclaimer } from './disclaimer.reducer';
import { PageDataBase, PageID } from '../models';
import { AppError } from './general.reducer';

@Injectable()
export class DisclaimerEffects {
  load$ = this.actions$.pipe(ofType(LoadDisclaimer));
  constructor(private actions$: Actions, private api: ApiService) {}
  onLoad$ = createEffect(() =>
    this.load$.pipe(
      switchMap(() =>
        firstValueFrom(this.api.getPage<PageDataBase>(PageID.DISCLAIMER))
          .then((data) => LoadedDisclaimer({ data }))
          .catch((error) => AppError({ error }))
      )
    )
  );
}
