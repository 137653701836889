import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { HomeDataLoaded, LoadHomeData } from './home.reducer';
import { firstValueFrom, switchMap } from 'rxjs';
import { ApiService } from '../services/api.service';
import { AppError } from './general.reducer';

@Injectable()
export class HomeEffects {
  load$ = this.actions$.pipe(ofType(LoadHomeData));
  constructor(private actions$: Actions, private api: ApiService) {}
  onload$ = createEffect(() =>
    this.load$.pipe(
      switchMap(() =>
        firstValueFrom(this.api.getHome())
          .then((data) => HomeDataLoaded({ data }))
          .catch((err) =>
            AppError({
              error: {
                message: 'Kon home data niet ophalen',
              },
            })
          )
      )
    )
  );
}
