import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Banner, PageID } from '../../models';
import { Store } from '@ngrx/store';
import { GeneralState } from '../../store/general.reducer';
import { selectLinks } from '../../store/selectors';
import { filter, mergeMap } from 'rxjs';
import { CaVideoDirective } from '@ca/ca-ng-core';

@Component({
  selector: 'ca-header',
  template: `<div class="header" [ngClass]="shape">
    <div class="logo-container">
      <img
        src="../../assets/brugge-kaas.png"
        alt="logo"
        class="logo"
        [routerLink]="homeLink$ | async" />
    </div>

    <div class="desktop-hero">
      <img
        *ngIf="media?.image as img"
        [src]="base + img.src"
        [srcset]="base + img.src_mobile + ' x1, ' + base + img.src + ' x2'"
        [alt]="img.alt" />
    </div>

    <div class="video-container">
      <video *ngIf="media?.video as video" autoplay playsinline muted caVideo>
        <!-- TODO: @Mitch media query instellen -->
        <source [src]="base + video.src" media="screen and (min-width: 760px)" />
        <source [src]="base + video.src_mobile" media="screen and (max-width: 760px)" />
      </video>
    </div>
    <h1 *ngIf="title">{{ title }}</h1>
  </div>`,
  styles: [],
})
export class HeaderComponent implements AfterViewInit {
  @ViewChild(CaVideoDirective) video?: CaVideoDirective;
  @Input() title?: string;
  @Input() shape: 'normal' | 'rounded' = 'normal';
  @Input() media?: Banner;
  homeLink$ = this.store
    .select(selectLinks)
    .pipe(filter((l, i) => l[i].id === PageID.HOME))
    .pipe(mergeMap((links) => (links.length > 0 ? ['/' + links[0].metaUrl] : ['/'])));
  constructor(private store: Store<{ general: GeneralState }>) {}

  ngAfterViewInit(): void {
    if (this.video) {
      this.video.element.onended = () => {
        // pause the video

        if (this.video) {
          this.video.element.pause();
          // set play time to the last frame
          this.video.element.currentTime = this.video.element.duration;
        }
      };

      const startPlayPromise = this.video.element.play();
      if (startPlayPromise !== undefined) {
        console.log('playing video muted');
        this.video.element.muted = true;
        startPlayPromise
          .then(() => {
            // Start whatever you need to do only after playback
            // has begun.
          })
          .catch((error) => {
            if (this.video) {
              console.error(error);
              if (error.name === 'NotAllowedError') {
                // showPlayButton(videoElem);
              } else {
                // Handle a load or playback error
              }
            }
          });
      }
    }
  }
  base = environment.apiBaseUrl;
}
