import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { LoadedRecipe, LoadedRecipes, LoadRecipe, LoadRecipes } from './recipes.reducer';
import { firstValueFrom, switchMap } from 'rxjs';
import { ApiService } from '../services/api.service';
import { AppError } from './general.reducer';

@Injectable()
export class RecipesEffects {
  load$ = this.actions$.pipe(ofType(LoadRecipes));
  loadDetail$ = this.actions$.pipe(ofType(LoadRecipe));
  constructor(private actions$: Actions, private api: ApiService) {}

  onLoad$ = createEffect(() =>
    this.load$.pipe(
      switchMap(() =>
        firstValueFrom(this.api.getRecipes())
          .then((data) =>
            LoadedRecipes({
              data,
            })
          )
          .catch((error) => AppError({ error }))
      )
    )
  );
  onLoadDetail$ = createEffect(() =>
    this.loadDetail$.pipe(
      switchMap(({ metaUrl }) =>
        firstValueFrom(this.api.getRecipe(metaUrl))
          .then((data) =>
            LoadedRecipe({
              data,
            })
          )
          .catch((error) => AppError({ error }))
      )
    )
  );
}
