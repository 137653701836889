import { createAction, createReducer, on, props } from '@ngrx/store';
import { RecipeDetailPageData, RecipeListItemData, RecipesListPageData } from '../models';
import { SetLanguage } from './general.reducer';

export interface RecipesState {
  data?: RecipesListPageData;
  all: RecipeListItemData[];
  filtered: RecipeListItemData[];
  detail?: RecipeDetailPageData;
}

export enum RecipesActions {
  load = '[Recipes] load',
  loaded = '[Recipes] loaded',
  loadDetail = '[Recipe Detail] load',
  loadDetailById = '[Recipe Detail] load by id',
  loadedDetail = '[Recipes Detail] loaded',
  filter = '[Recipes] filter',
}

export const LoadRecipes = createAction(RecipesActions.load);
export const LoadedRecipes = createAction(
  RecipesActions.loaded,
  props<{ data: RecipesListPageData }>()
);

export const LoadRecipe = createAction(
  RecipesActions.loadDetail,
  props<{ metaUrl: number | string }>()
);
export const LoadedRecipe = createAction(
  RecipesActions.loadedDetail,
  props<{ data: RecipeDetailPageData }>()
);

export const FilterRecipes = createAction(
  RecipesActions.filter,
  props<{ moment?: number; cheese?: number }>()
);

export const initialState: RecipesState = {
  all: [],
  filtered: [],
};

export const reducer = createReducer(
  initialState,
  on(LoadedRecipes, (_, { data }) => ({ ..._, data, all: data.recipes, filtered: data.recipes })),
  on(LoadedRecipe, (_, { data }) => ({ ..._, detail: data })),
  on(FilterRecipes, (_, { cheese, moment }) => ({
    ..._,
    filtered: _.all.filter(
      (r) =>
        (!cheese || r.cheeses.findIndex((c) => c.id.toString() === cheese.toString()) !== -1) &&
        (!moment || r.moments.findIndex((m) => m.id.toString() === moment.toString()) !== -1)
    ),
  })),
  on(SetLanguage, () => initialState)
);
