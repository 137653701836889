import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { BruggeKaasState } from '../../store';
import { CaSubscriber } from '@ca/ca-utils';
import { FilterCheeses, LoadCheesesListData } from '../../store/cheeses.reducer';
import {
  selectAllCheeses,
  selectCheeseMomentFilterOptions,
  selectCheesesSelectHint,
  selectFilteredCheeses,
  selectLanguage,
  selectMomentSelectHint,
} from '../../store/selectors';
import { environment } from '../../../environments/environment';
import { Meta } from '@angular/platform-browser';
import { SeoService } from '../../services/seo.service';

@Component({
  selector: 'ca-cheeses-list',
  templateUrl: './cheeses-list.component.html',
})
export class CheesesListComponent implements OnDestroy {
  base = environment.apiBaseUrl;
  private sub = new CaSubscriber();
  all$ = this.store.select(selectAllCheeses);
  filtered$ = this.store.select(selectFilteredCheeses);
  momentFilters$ = this.store.select(selectCheeseMomentFilterOptions);
  momentSelectHint$ = this.store.select(selectMomentSelectHint);
  cheesesSelectHint$ = this.store.select(selectCheesesSelectHint);

  data$ = this.store.select((state) => state.cheeses.listPageData);

  constructor(private store: Store<BruggeKaasState>, private seo: SeoService) {
    this.sub.subscribe(this.store.select(selectLanguage), () =>
      this.store.dispatch(LoadCheesesListData())
    );
    this.sub.subscribe(this.data$, (d) => this.seo.setMeta(d));
  }

  ngOnDestroy(): void {
    this.sub.closeSubscriptions();
  }
  async filterChange(e: string) {
    // TODO: fix
    this.store.dispatch(FilterCheeses({ moment: e !== 'null' ? +e : null }));
  }

  momentFilter: string | null = null;
  cheeseFilter: string | null = null;
}
