<ng-container *ngIf="data$ | async as page">
  <ca-header shape="rounded"></ca-header>

  <div class="uneven basic-contact">
    <h1>{{ page.title }}</h1>

    <div *ngIf="page.text.length > 0" [outerHTML]="page.text"></div>

    <p>
      <strong>{{ page.companyName }}</strong>
      <br />
      <strong
        >{{ page.address.streetName }} {{ page.address.streetNumber }},
        {{ page.address.postalCode }} {{ page.address.city }}
      </strong>
      <br />
      <a *ngIf="page.phone" [href]="'callto:' + page.phone" class="tel-number">
        <span class="material-symbols-outlined"> call </span> {{ page.phone }}</a
      >
    </p>

    <form #contactForm id="contactForm" class="contact-form" *ngIf="page.labels !== undefined">
      <div class="custom-form-field">
        <label for="first_name">{{ page.labels.first_name }}</label>
        <input
          autofocus
          type="text"
          name="first_name"
          [(ngModel)]="form.first_name"
          required
          (ngModelChange)="validate()"
          [class.has-errors]="_errors['first_name']"
          placeholder="*" />
        <span class="field-error" *ngIf="_errors['first_name'] as error">{{ error }}</span>
      </div>

      <div class="custom-form-field">
        <label for="last_name">{{ page.labels.last_name }}</label>
        <input
          type="text"
          name="last_name"
          [(ngModel)]="form.last_name"
          [class.has-errors]="_errors['last_name']"
          (ngModelChange)="validate()"
          required
          placeholder="*" />
        <span class="field-error" *ngIf="_errors['last_name'] as error">{{ error }}</span>
      </div>

      <div class="custom-form-field">
        <label for="email">{{ page.labels.email }}</label>
        <input
          type="text"
          name="email"
          [(ngModel)]="form.email"
          [class.has-errors]="_errors['email']"
          (ngModelChange)="validate()"
          required
          placeholder="*" />
        <span class="field-error" *ngIf="_errors['email'] as error">{{ error }}</span>
      </div>

      <div class="custom-form-field">
        <label for="tel">{{ page.labels.phone }}</label>
        <input
          type="text"
          name="tel"
          [(ngModel)]="form.tel"
          [class.has-errors]="_errors['tel']"
          (ngModelChange)="validate()"
          placeholder="..." />
        <span class="field-error" *ngIf="_errors['tel'] as error">{{ error }}</span>
      </div>

      <div class="custom-form-field">
        <label for="street">{{ page.labels.street }}</label>
        <input
          type="text"
          name="street"
          [class.has-errors]="_errors['street']"
          (ngModelChange)="validate()"
          [(ngModel)]="form.street"
          placeholder="..." />
        <span class="field-error" *ngIf="_errors['street'] as error">{{ error }}</span>
      </div>

      <div class="custom-form-field">
        <label for="street_nr">{{ page.labels.street_number }}</label>
        <input
          type="text"
          name="street_nr"
          [class.has-errors]="_errors['street_nr']"
          (ngModelChange)="validate()"
          [(ngModel)]="form.street_nr"
          placeholder="..." />
        <span class="field-error" *ngIf="_errors['street_nr'] as error">{{ error }}</span>
      </div>

      <div class="custom-form-field">
        <label for="postal_code">{{ page.labels.postal_code }}</label>
        <input
          type="text"
          name="postal_code"
          [class.has-errors]="_errors['postal_code']"
          (ngModelChange)="validate()"
          [(ngModel)]="form.postal_code"
          placeholder="..." />
        <span class="field-error" *ngIf="_errors['postal_code'] as error">{{ error }}</span>
      </div>

      <div class="custom-form-field">
        <label for="city">{{ page.labels.city }}</label>
        <input
          type="text"
          name="city"
          [class.has-errors]="_errors['city']"
          (ngModelChange)="validate()"
          [(ngModel)]="form.city"
          placeholder="..." />
        <span class="field-error" *ngIf="_errors['city'] as error">{{ error }}</span>
      </div>

      <div class="custom-form-field">
        <p class="mock-label">{{ page.labels.country }}</p>
        <div class="select">
          <select
            name="country"
            [(ngModel)]="form.country"
            [class.has-errors]="_errors['country']"
            (ngModelChange)="validate()"
            class="country-select"
            required>
            <option [value]="null">
              <span class="required-styling">* </span>{{ selectCountryHint$ | async }}
            </option>
            <option *ngFor="let country of countries" [value]="country.code">
              {{ country.name }}
            </option>
          </select>
        </div>
        <span class="field-error" *ngIf="_errors['country'] as error">{{ error }}</span>
      </div>

      <div class="custom-form-field">
        <p class="mock-label">{{ page.labels.subject }}</p>
        <div class="select">
          <select
            name="subject"
            [(ngModel)]="form.subject"
            [class.has-errors]="_errors['subject']"
            (ngModelChange)="validate()"
            class="contact-select"
            required>
            <option [value]="null">
              <span class="required-styling">* </span>
            </option>
            <option value="products">{{ page.labels.subjectOptionProducts }}</option>
            <option value="sponsoring">{{ page.labels.subjectOptionSponsoring }}</option>
            <option value="press">{{ page.labels.subjectOptionPress }}</option>
            <option value="jobs">{{ page.labels.subjectOptionJobs }}</option>
            <option value="complaints">{{ page.labels.subjectOptionComplaints }}</option>
            <option value="other">{{ page.labels.subjectOptionOther }}</option>
          </select>
        </div>
        <span class="field-error" *ngIf="_errors['subject']">{{ _errors['subject'] }}</span>
      </div>

      <div class="custom-form-field">
        <label for="message">{{ page.labels.message }}</label>
        <textarea
          type="text"
          [(ngModel)]="form.message"
          (ngModelChange)="validate()"
          name="message"
          required
          [class.has-errors]="_errors['message']"
          placeholder="*"></textarea>
        <span class="field-error" *ngIf="_errors['message'] as error">{{ error }}</span>
      </div>

      <div class="custom-form-field">
        <p class="fake-label">{{ page.labels.attachment }}</p>
        <label for="files" class="custom-file-upload" (click)="fileInput.click()">{{
          page.labels.hintAttachment
        }}</label>
        <input
          type="file"
          name="files"
          [(ngModel)]="form.files"
          (ngModelChange)="validate()"
          #fileInput
          [class.has-errors]="_errors['files']"
          multiple
          (change)="onFileChange($event)" />
        <span class="field-error" *ngIf="_errors['files'] as error">{{ error }}</span>

        <span *ngFor="let file of files">
          <a class="material-symbols-outlined" (click)="removeFile(file.name, fileInput)">delete</a>
          <p>{{ file.name }} {{ file.size / 1024 | number : '1.0-2' }}kB</p></span
        >
      </div>

      <button [disabled]="submitted && hasErrors" (click)="submit()">{{ page.labels.send }}</button>
    </form>
  </div>
</ng-container>
