import { onlyUniqueIndexedNames } from '@ca/ca-utils';
import { BruggeKaasState } from '.';
import {
  CheeseDetailPageData,
  MomentDetailPageData,
  PageDataBase,
  PageID,
  RecipeDetailPageData,
} from '../models';
import { GeneralState } from './general.reducer';
import { LandingState } from './landing.reducer';
import { CheesesState } from './cheeses.reducer';
import { RecipesState } from './recipes.reducer';
import { getRandomItemsFromArray } from '@ca/core';
import { SustainabilityState } from './sustainability.reducer';
import { createSelector } from '@ngrx/store';

//#region Landing
export const selectLanding = (state: { landing: LandingState }) => state.landing;
//#endregion

//#region Menu
export const selectPrimaryMenuItems = (state: { general: GeneralState }) =>
  state.general.links.filter((l) => l.location === 'nav:primary');

export const selectSecondaryMenuItems = (state: { general: GeneralState }) =>
  state.general.links.filter((l) => l.location === 'nav:secondary');
//#endregion

//#region Home
export const selectHomeData = (state: BruggeKaasState) => state.home;
//#endregion

//#region General
export const selectLanguage = (state: { general: GeneralState }) => state.general.language;
export const selectRegion = (state: { general: GeneralState }) => state.general.region;
export const selectShortcutData = (state: { general: GeneralState }) => {
  const cheesesLink = state.general.links.find((e) => e.id === +PageID.CHEESES)?.metaUrl;
  if (!cheesesLink) throw new Error('Link to our cheeses not found!');
  return {
    instagram: state.general.instagram,
    facebook: state.general.facebook,
    cheesesLink: [cheesesLink],
  };
};
export const selectLinks = (state: { general: GeneralState }) => state.general.links;
export const selectTranslations = (state: { general: GeneralState }) => state.general.translations;
export const selectTranslatedErrors = (state: { general: GeneralState }) => ({
  isAlpha: state.general.translations.isAlphaError,
  isEmail: state.general.translations.isEmailError,
  isString: state.general.translations.isStringError,
  minLength: state.general.translations.minLengthError,
  maxLength: state.general.translations.maxLengthError,
});
export const selectRecipesListTitle = (state: { general: GeneralState }) =>
  state.general.translations.recipesListTitle;

export const selectRouterLinkHome = (state: { general: GeneralState }) =>
  '/' + state.general.links.find((e) => e.id === PageID.HOME)?.metaUrl;
//#endregion

//#region Contact
export const selectAddress = (state: { general: GeneralState }) => state.general.address;
export const selectSocials = (state: { general: GeneralState }) => ({
  instagram: state.general.instagram,
  facebook: state.general.facebook,
});
export const selectContactPageData = (state: BruggeKaasState) => ({
  title: state.contact.title,
  countries: state.contact.countries,
  text: state.contact.text,
  seo: state.contact.seo,

  address: state.general.address,
  companyName: state.general.companyName,
  phone: state.general.phone,
  instagram: state.general.instagram,
  facebook: state.general.facebook,
  labels: {
    ...state.general.translations,
    first_name: state.general.translations.lblFirstName,
    last_name: state.general.translations.lblLastName,
    email: state.general.translations.lblEmail,
    phone: state.general.translations.lblPhone,
    street: state.general.translations.lblAddressStreet,
    street_number: state.general.translations.lblAddressNumber,
    postal_code: state.general.translations.lblAddressPostalCode,
    city: state.general.translations.lblAddressCity,
    country: state.general.translations.lblAddressCountry,
    subject: state.general.translations.lblSubject,
    message: state.general.translations.lblMessage,
    attachment: state.general.translations.lblAttachment,
    hintAttachment: state.general.translations.hintAttachment,
    send: state.general.translations.btnSend,

    isAlpha: state.general.translations.isAlphaError,
    isEmail: state.general.translations.isEmailError,
    isString: state.general.translations.isStringError,
    minLength: state.general.translations.minLengthError,
    maxLength: state.general.translations.maxLengthError,
  },
});
export const selectCountryHint = (state: { general: GeneralState }) =>
  state.general.translations.countriesSelectHint;

//#endregion

//#region Cheeses

export const selectCheeses = (state: BruggeKaasState) => state.cheeses;
export const selectCheesesListData = createSelector(selectCheeses, (s) => s);
export const selectFilteredCheeses = createSelector(selectCheeses, (s) => s.filtered);
export const selectAllCheeses = createSelector(selectCheeses, (s) => s.all);
export const selectCheeseMomentFilterOptions = createSelector(selectCheeses, (s) =>
  s.filtered.flatMap((c) => c.moments).filter(onlyUniqueIndexedNames)
);
export const selectCheeseDetail = createSelector(selectCheeses, (state) => state.detail);
export const selectRecipesRelatedToCheese =
  (cheese: CheeseDetailPageData) =>
  (state: { general: GeneralState; cheeses: CheesesState; recipes: RecipesState }) => {
    console.log('finding related recipes for', cheese.id, cheese.name);
    console.log('#recipes in store', state.recipes.all.length);
    const cheeseToMatch = cheese.id;
    let _recipes = state.recipes.all.filter(
      (r) => r.cheeses.findIndex((c) => c.id === cheeseToMatch) >= 0
    );
    console.log('found', _recipes.length, 'recipes related by cheese');
    if (_recipes.length < 2) {
      const additionsFromAll = getRandomItemsFromArray(state.recipes.all, 2 - _recipes.length);
      console.log('added', additionsFromAll.length, 'recipes from all');
      _recipes = [..._recipes, ...additionsFromAll];
    }
    return getRandomItemsFromArray(_recipes, 2);
  };

export const selectRelatedRecipesTitle = (state: { general: GeneralState }) =>
  state.general.translations.relatedRecipes;
export const selectMomentSelectHint = (state: { general: GeneralState }) =>
  state.general.translations.momentSelectHint;
export const selectCheesesSelectHint = (state: { general: GeneralState }) =>
  state.general.translations.cheeseSelectHint;
//#endregion

//#region Recipe
export const selectRecipes = (state: { recipes: RecipesState }) => state.recipes.all;
export const selectRecipe = (state: { recipes: RecipesState }) => state.recipes.detail;
export const selectRecipesRelatedToRecipe =
  (recipe: RecipeDetailPageData['data']) => (state: { recipes: RecipesState }) => {
    console.log('finding related recipes for', recipe.id, recipe.name);
    console.log('#recipes in store', state.recipes.all.length);
    const cheesesToMatch = recipe.cheeses.map((c) => c.id.toString());
    console.log(
      'cheesesToMatch',
      recipe.cheeses.map((e) => ({ name: e.name, id: e.id }))
    );
    const recipes = state.recipes.all.filter(
      (r) =>
        r.cheeses.findIndex((c) => cheesesToMatch.includes(c.id.toString())) >= 0 &&
        r.id.toString() !== recipe.id.toString()
    );
    console.log('found', recipes.length, 'recipes related by cheese');

    const take = 2;
    let relatedRecipes = getRandomItemsFromArray(recipes, take);
    console.log('recipes found by cheese', relatedRecipes.length);
    if (relatedRecipes.length < take) {
      const additionsFromAll = getRandomItemsFromArray(
        state.recipes.all.filter((r) => r.id !== recipe.id),
        take - relatedRecipes.length
      );
      console.log('added', additionsFromAll.length, 'recipes from all');
      relatedRecipes = [...relatedRecipes, ...additionsFromAll];
    }
    console.log('found', relatedRecipes.length, 'recipes');
    return relatedRecipes;
  };
export const selectRecipesRelated = (state: { recipes: RecipesState }) => {
  console.log(
    'finding related recipes for',
    state.recipes.detail?.data.id,
    state.recipes.detail?.data.name
  );
  console.log('#recipes in store', state.recipes.all.length);
  const cheesesToMatch = state.recipes.detail?.data.cheeses.map((c) => c.id.toString());
  console.log(
    'cheesesToMatch',
    state.recipes.detail?.data.cheeses.map((e) => ({ name: e.name, id: e.id }))
  );
  if (!cheesesToMatch) return [];
  const recipes = state.recipes.all.filter(
    (r) =>
      r.cheeses.findIndex((c) => cheesesToMatch.includes(c.id.toString())) >= 0 &&
      r.id.toString() !== state.recipes.detail?.data.id.toString()
  );
  console.log('found', recipes.length, 'recipes related by cheese');

  const take = 2;
  let relatedRecipes = getRandomItemsFromArray(recipes, take);
  console.log('recipes found by cheese', relatedRecipes.length);
  if (relatedRecipes.length < take) {
    const additionsFromAll = getRandomItemsFromArray(
      state.recipes.all.filter((r) => r.id !== state.recipes.detail?.data.id),
      take - relatedRecipes.length
    );
    console.log('added', additionsFromAll.length, 'recipes from all');
    relatedRecipes = [...relatedRecipes, ...additionsFromAll];
  }
  console.log('found', relatedRecipes.length, 'recipes');
  return relatedRecipes;
};

export const selectRecipePage = (state: { recipes: RecipesState }) => {
  return {
    relatedRecipes: selectRecipesRelated(state),
    detail: selectRecipe(state),
  };
};
export const selectFilteredRecipes = (state: { recipes: RecipesState }) => state.recipes.filtered;
export const selectRecipeCheeseFilterOptions = (state: BruggeKaasState) =>
  state.recipes.all.flatMap((c) => c.cheeses).filter(onlyUniqueIndexedNames);
export const selectRecipeMomentFilterOptions = (state: BruggeKaasState) =>
  state.recipes.all.flatMap((c) => c.moments).filter(onlyUniqueIndexedNames);
export const selectRecipeLabels = (state: { general: GeneralState }) => ({
  preparation: state.general.translations.preparationLabel,
  download: state.general.translations.downloadRecipeLabel,
  ingredients: state.general.translations.ingredientsLabel,
  related: state.general.translations.relatedRecipes,
});
//#endregion

//#region Sustainability
export const selectSustainabilityPageData = (state: { sustainability: SustainabilityState }) =>
  state.sustainability;
//#endregion

//#region Moment
export const selectCheesesAndRecipesForMoment =
  (moment: MomentDetailPageData['data']) => (state: BruggeKaasState) => {
    const momentId = moment.id;
    const cheeses = getRandomItemsFromArray(
      state.cheeses.all.filter((c) => c.moments.findIndex((m) => m.id === momentId) !== -1),
      6
    );
    const recipes = getRandomItemsFromArray(
      state.recipes.all.filter((c) => c.moments.findIndex((m) => m.id === momentId) !== -1),
      6
    );
    return {
      cheeses,
      recipes,
    };
  };
//#endregion

//#region Privacy Policy
export const selectPrivacyPolicyState = (state: { privacyPolicy: PageDataBase }) =>
  state.privacyPolicy;
//#endregion

//#region Disclaimer
export const selectDisclaimerState = (state: { disclaimer: PageDataBase }) => state.disclaimer;
//#endregion
