import { Component, OnDestroy } from '@angular/core';
import { CaSubscriber } from '@ca/ca-utils';
import { LoadCheeseDetail } from '../../store/cheeses.reducer';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Params } from '@angular/router';
import { CheeseDetailPageData, ImageData, RecipeListItemData } from '../../models';
import {
  selectCheeseDetail,
  selectLanguage,
  selectRecipesRelatedToCheese,
  selectRelatedRecipesTitle,
} from '../../store/selectors';
import { environment } from '../../../environments/environment';
import { BruggeKaasState } from '../../store';
import { LoadRecipes } from '../../store/recipes.reducer';
import { SeoService } from '../../services/seo.service';

@Component({
  selector: 'ca-cheese-detail',
  templateUrl: './cheese-detail.component.html',
})
export class CheeseDetailComponent implements OnDestroy {
  private sub = new CaSubscriber();
  data?: CheeseDetailPageData;
  gallerySources?: ImageData[];
  recipes?: RecipeListItemData[];
  base = environment.apiBaseUrl;

  listTitle$ = this.store.select(selectRelatedRecipesTitle);

  constructor(
    private route: ActivatedRoute,
    private store: Store<BruggeKaasState>,
    private seo: SeoService
  ) {
    this.sub.subscribe(this.route.params, (params: Params) => {
      const metaUrl = params['metaUrl'];
      if (metaUrl) this.store.dispatch(LoadCheeseDetail({ metaUrl }));
    });

    this.sub.subscribe(this.store.select(selectLanguage), () => {
      if (this.data) {
        this.store.dispatch(LoadRecipes());
        this.store.dispatch(LoadCheeseDetail({ metaUrl: this.data.id }));
      }
    });

    this.sub.subscribe(this.store.select(selectCheeseDetail), {
      next: (detail) => {
        this.data = detail;
        this.seo.setMeta(detail);

        this.gallerySources = detail?.gallery.map((i) => ({
          alt: i.alt,
          src: this.base + i.src,
        }));

        if (detail)
          this.sub.subscribe(this.store.select(selectRecipesRelatedToCheese(detail)), {
            next: (recipes) => {
              this.recipes = recipes;
            },
          });
      },
    });
  }

  ngOnDestroy(): void {
    this.sub.closeSubscriptions();
  }
}
