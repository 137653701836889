import { createAction, createReducer, on, props } from '@ngrx/store';
import { HomePageData, SEO } from '../models';
import { SetLanguage } from './general.reducer';

export type HomeState = Omit<HomePageData, 'seo'> & { seo?: SEO };

export const initialState: HomeState = {
  cheeses: [],
  moments: [],
  text: '',
  title: 'Brugge kaas',
  banner: null,
};

export enum HomeActions {
  load = '[Home Page Data] load',
  loaded = '[Home Page Data] loaded',
}

export const LoadHomeData = createAction(HomeActions.load);
export const HomeDataLoaded = createAction(HomeActions.loaded, props<{ data: HomePageData }>());
// export const LoadHomeData

export const reducer = createReducer(
  initialState,
  on(HomeDataLoaded, (_, { data }) => data),
  on(SetLanguage, () => initialState)
);
