<ca-header shape="rounded"></ca-header>
<ng-container *ngIf="recipe && (labels$ | async) as labels">
  <!-- <div class="recipe-img">
    <img [src]="base + recipe.gallery[0].src" [alt]="recipe.gallery[0].alt" />
  </div> -->
  <div class="carousel" *ngIf="gallerySources as gallery">
    <ca-carousel [img_list]="gallery"></ca-carousel>
  </div>
  <div class="recipe-container">
    <h1>{{ recipe.name }}</h1>

    <div class="cooking-info">
      <div *ngIf="recipe.numberOfPersons">
        <span class="material-symbols-outlined">person</span>
        <p>{{ recipe.numberOfPersons }} pers.</p>
      </div>
      <div *ngIf="recipe.perparationTime">
        <span class="material-symbols-outlined">schedule</span>
        <p>{{ recipe.perparationTime }} min</p>
      </div>
    </div>

    <h2 class="recipe-subtitle">{{ labels.ingredients }}</h2>

    <div class="ingredients-list" [innerHTML]="recipe.ingredients"></div>

    <h2 class="recipe-subtitle">{{ labels.preparation }}</h2>
    <div *ngIf="videoUrl" class="recipe-video">
      <iframe
        [src]="videoUrl"
        frameborder="0"
        allow="autoplay; encrypted-media"
        allowfullscreen></iframe>
    </div>
    <div class="instructions" [innerHTML]="recipe.preparation"></div>
    <button class="download" onclick="window.print()">{{ labels.download }}</button>
  </div>

  <ng-container *ngIf="recipes as relatedRecipes">
    <div class="related-recipes recipes-list">
      <h2>{{ labels.related }}</h2>
      <a
        *ngFor="let recipe of relatedRecipes"
        class="recipes-list-item"
        [routerLink]="recipe.routerLink">
        <img [alt]="recipe.image.alt" [src]="base + recipe.image.src" />
        <div class="info">
          <h4>
            <span *ngFor="let t of recipe.recipeTypes">{{ t.name }} </span>
          </h4>
          <h3>{{ recipe.name }}</h3>
          <div class="cooking-time" *ngIf="recipe.preparationTime">
            <span class="material-symbols-outlined">schedule</span>
            <p>{{ recipe.preparationTime }} min</p>
          </div>
        </div>
      </a>
    </div>
  </ng-container>
</ng-container>
