export type CA_TML_LinkType =
  | 'deal'
  | 'quotation'
  | 'contact'
  | 'company'
  | 'project'
  | 'invoice'
  | 'task';

export function FocusLink(resource_name_singular: CA_TML_LinkType, id: string) {
  switch (resource_name_singular) {
    case 'deal':
      return `https://focus.teamleader.eu/web/deals/${id}`;
    case 'invoice':
      return `https://focus.teamleader.eu/invoice_detail.php?id=${id}`;
    case 'task':
      return `https://focus.teamleader.eu/task_detail.php?id=${id}`;
    case 'company':
      return `https://focus.teamleader.eu/companies/${id}`;
    default:
      return `https://focus.teamleader.eu/${resource_name_singular}s/${id}`;
  }
}

export function NotionLink(id: string) {
  return `https://www.notion.so/cigar-agency/${id.replace('-', '')}`;
}

/**
 * @param type
 * @param name for type client_project set name to: [client_name]/[project_name]
 * @returns url
 */
export function DropboxLink(type: 'client' | 'client_project' | 'internal', name: string) {
  switch (type) {
    case 'client':
    case 'client_project':
      return `https://www.dropbox.com/home/CIGAR.AGENCY/Klanten/${name}`;
    case 'internal':
      return `https://www.dropbox.com/home/CIGAR.AGENCY/Intern/${name}`;
  }
}
