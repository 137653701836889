import { createAction, createReducer, on, props } from '@ngrx/store';
import { SetLanguage } from './general.reducer';
import { Banner, PageDataBase } from '../models';

export const initialState: PageDataBase = {
  title: 'Disclaimer',
  text: '',
  banner: null,
  seo: {
    title: '',
    description: '',
    image: undefined,
  },
};

export enum DisclaimerActions {
  load = '[Disclaimer] load',
  loaded = '[Disclaimer] loaded',
}
export const LoadDisclaimer = createAction(DisclaimerActions.load);
export const LoadedDisclaimer = createAction(
  DisclaimerActions.loaded,
  props<{ data: PageDataBase }>()
);

export const reducer = createReducer(
  initialState,
  on(LoadedDisclaimer, (_, { data }) => ({ ...data })),
  on(SetLanguage, () => initialState)
);
