import { Component, OnDestroy, OnInit } from '@angular/core';
import { BruggeKaasState } from '../../store';
import { Store } from '@ngrx/store';
import {
  selectFilteredRecipes,
  selectLanguage,
  selectRecipeCheeseFilterOptions,
  selectRecipeMomentFilterOptions,
  selectRecipes,
} from '../../store/selectors';
import { FilterRecipes, LoadRecipes } from '../../store/recipes.reducer';
import { CaSubscriber } from '@ca/ca-utils';
import { environment } from '../../../environments/environment';
import { Meta } from '@angular/platform-browser';
import { SeoService } from '../../services/seo.service';

@Component({
  selector: 'ca-recipes-list',
  templateUrl: './recipes-list.component.html',
})
export class RecipesListComponent implements OnDestroy {
  private sub = new CaSubscriber();

  all$ = this.store.select(selectRecipes);
  filtered$ = this.store.select(selectFilteredRecipes);
  momentFilters$ = this.store.select(selectRecipeMomentFilterOptions);
  cheeseFilters$ = this.store.select(selectRecipeCheeseFilterOptions);
  data$ = this.store.select((state) => state.cheeses.listPageData);
  base = environment.apiBaseUrl;

  constructor(private store: Store<BruggeKaasState>, private seo: SeoService) {
    this.sub.subscribe(this.store.select(selectLanguage), () => {
      this.store.dispatch(LoadRecipes());
    });
    this.sub.subscribe(this.data$, (d) => this.seo.setMeta(d));
  }

  ngOnDestroy(): void {
    this.sub.closeSubscriptions();
  }

  async filterChange() {
    this.store.dispatch(
      FilterRecipes({
        moment: this.momentFilter ? +this.momentFilter : undefined,
        cheese: this.cheeseFilter ? +this.cheeseFilter : undefined,
      })
    );
  }

  momentFilter: string | null = null;
  cheeseFilter: string | null = null;
}
