export type SupportedLanguage = 'en' | 'nl' | 'fr' | 'es';
export type Region = 'US' | 'world';

export enum PageID {
  LANDING = 10,
  HOME = 1,
  CHEESES = 2,
  RECIPES = 3,
  MOMENTS = 4,
  CONTACT = 6,

  SUSTAINABILITY = 5,
  CHEESE_DETAIL = 7,
  RECIPE_DETAIL = 8,
  MOMENT_DETAIL = 9,

  DISCLAIMER = 11,
  PRIVACY_AND_COOKIES = 12,
}
export type General = GeneralData & Wrapper;

export interface GeneralData {
  pages: PageInfo[];
  settings: {
    streetAddress: {
      streetName: string; //'Stationstraat';
      streetNumber: string; //'149';
      postalCode: string; //'8890';
      city: string; //'MOORSLEDE';
    };
    companyName: string; //'Milcobel Moorslede';
    phone: string; //'+32 51 270 600';
    facebook: string; //'https://www.facebook.com/bruggekaas/';
    instagram: string; //'https://www.instagram.com/bruggekaas';
  };
  translates: {
    lblFirstName: string; //'Voornaam';
    lblLastName: string; //'Naam';
    lblEmail: string; //'Email';
    lblPhone: string; //'Tel.';
    lblAddressStreet: string; //'Straat';
    lblAddressNumber: string; //'Nr.';
    lblAddressPostalCode: string; //'Postcode';
    lblAddressCity: string; //'Gemeente';
    lblAddressCountry: string; //'Land';
    lblSubject: string; //'Onderwerp';
    subjectOptionProducts: string;
    subjectOptionSponsoring: string;
    subjectOptionPress: string;
    subjectOptionJobs: string;
    subjectOptionComplaints: string;
    subjectOptionOther: string;

    lblMessage: string; //'Boodschap';
    lblAttachment: string; //'Bijlage';
    hintAttachment: string;
    btnUpload: string; //'Uploaden';
    btnSend: string; //'Versturen';

    cheeseFilterLabel: string;
    cheeseSelectHint: string;

    momentFilterLabel: string;
    momentSelectHint: string;

    countriesSelectHint: string;

    relatedRecipes: string; //'Recepten met';
    cheesesListTitle: string;
    recipesListTitle: string;

    ingredientsLabel: string;
    preparationLabel: string;
    downloadRecipeLabel: string;

    // errors
    formSuccessMessage: string; //'Contact success message here...';
    formErrorMessage: string; //'Contact error message here...';

    fileSizeError: string;
    maxLengthError: string;
    minLengthError: string;
    isAlphaError: string;
    isEmailError: string;
    isStringError: string;
  };
}
export type Ref = { id: number; name: string };

export interface RecipeListItemData {
  id: number;
  name: string;
  ingredients: string[];
  preparation: string[];
  numberOfPersons: number;
  preparationTime: number;
  metaUrl: string;
  videoUrl: string;
  cheeses: Ref[];
  moments: Ref[];
  recipeTypes: Ref[];
  routerLink: string[];
  seo: SEO;
  image: ImageData;
}
export interface SustainabilityItemStep {
  id: number;
  title: string;
  subtitle: string;
  text: string;
  className: string;
  startImage?: ImageData;
  endImage?: ImageData;
}
export interface SustainabilityItem {
  id: number;
  className: string;
  title: string;
  text: string;
  steps: SustainabilityItemStep[];
  image?: ImageData;
}
//#region Shared
export type Banner = {
  video?: {
    src: string;
    src_mobile?: string;
    alt: string;
  };
  image?: {
    src: string;
    src_mobile?: string;
    alt: string;
  };
} | null;

export interface Wrapper {
  success: boolean;
  type: string;
  debug: boolean;
}

export type PageDataBase = {
  title: string;
  /**
   * HTML
   */
  text: string;
  seo: SEO;
  banner?: Banner;
};

export type ImageData = {
  alt: string;
  src: string;
};
export type VideoData = {
  alt: string;
  src: string;
  src_mobile: string;
};

export type SEO = { title: string; description: string; image?: Pick<ImageData, 'src'> };

export type CheeseShape = 'square' | 'rounded' | 'round';
export interface CheeseData {
  id: number;
  type: 'cheese';
  name: string; // 'Brugge Oud';
  routerLink: string[];
  shape: CheeseShape;
  moments: Ref[];
  /**
   * HTML
   */
  description: string; // '<p>Brugge Oud is een klassieker onder de kazen. Brugge Oud heeft zijn naam te danken aan de tijd die hij krijgt om te rijpen. Dit lange rijpingsproces zorgt voor een echte pittige, smaakvolle kaas met die typische witte puntjes of kaaskristallen aan de randen.</p>\r\n\t\t\t\t\r\n\t\t\t\t<p>Bovendien bevat Brugge Oud heel weinig lactose. Brugge Oud is een volwassen, zelfbewuste kaas die op een kaasplank alle aandacht naar zich toe trekt.&nbsp;</p>\r\n\t\t\t\t';
  gallery: ImageData[];
  seo: SEO;
  image: ImageData;
}
export type CheeseListItemData = Omit<CheeseData, 'gallery'>;
export type MomentListItemData = Omit<MomentData, 'gallery'>;

export interface MomentData {
  id: number;
  type: 'moment';
  routerLink: string[];
  name: string; //'Lunch';
  title: string; //'';
  from: string; // HH:mm:ss
  till: string; // HH:mm:ss
  seo: SEO;
  moments_lang_id: number; //9;
  image: ImageData;
  gallery: ImageData[];
}

export interface RouteInfo {
  id: PageID;
  type: 'route';
  routeParam: boolean; //false;
  metaUrl: string; //'';
  metaTitle: string; //'Brugge kaas';
}
export interface LinkInfo {
  id: PageID;
  type: 'link';
  routeParam: boolean;
  menuName: string;
  metaUrl: string;
  metaTitle: string;
  location: 'nav:primary' | 'nav:secondary';
}
export type PageInfo = RouteInfo | LinkInfo;
//#endregion

//#region Pages
export type HomePageData = PageDataBase & {
  moments: MomentData[];
  cheeses: CheeseData[];
};
export type LandingPageData = {
  banner: Banner;
  seo: SEO;
};
export type ContactsPageData = {
  title: string;
  text: string;
  seo: SEO;
  countries: { name: string; code: string }[];
};

export type CheesesListPageData = PageDataBase & {
  id: PageID.CHEESES;
  cheeses: CheeseListItemData[];
};
export type CheeseDetailPageData = {
  id: number;
  type: 'cheese';
  shape: CheeseShape;
  name: string;
  description: string;
  image: ImageData;
  gallery: ImageData[];
  moments: Ref[];
  routerLink: string[];
  seo: SEO;
};

export type RecipesListPageData = PageDataBase & {
  recipes: RecipeListItemData[];
};
export type RecipeDetailPageData = {
  data: {
    id: number;
    name: string;
    ingredients: string[];
    preparation: string[];
    numberOfPersons: number;
    perparationTime: number;
    videoUrl: string;
    gallery: ImageData[];
    cheeses: Ref[];
    moments: Ref[];
    recipeTypes: Ref[];
    metaUrl: string;
    routerLink: string[];
    seo: SEO;
    image: ImageData;
  };
};

export type MomentDetailPageData = {
  data: {
    id: number;
    type: 'moment';
    name: string;
    title: string;
    from: string; //"05:00:00",
    till: string; //"11:00:00",
    image: {
      alt: string; //"Ontbijt",
      src: string; //"\/files\/moments_00001_overview_img.jpg"
    };

    routerLink: string[];
    seo: SEO;
    metaUrl: string; //"ontbijt"
  };
};

export type SustainabilityPageData = {
  id: PageID.SUSTAINABILITY;
  banner: Banner;
  title: string;
  text: string;
  items: SustainabilityItem[];
  seo: SEO;
};
//#endregion

export type OptionalSEO<T extends { seo: SEO }> = Omit<T, 'seo'> & { seo?: SEO };
