// eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/ban-types
export declare let gtag: Function;

export class GA4 {
  constructor(private key: string) {}

  config(page_path: string) {
    gtag('config', this.key, { page_path: page_path });
  }

  allowAnalytics() {
    gtag('consent', 'update', {
      analytics_storage: 'granted',
    });
  }

  rejectAnalytics() {
    gtag('consent', 'update', {
      analytics_storage: 'denied',
    });
  }
}

export const ga4 = new GA4('G-6JV3N9814P');
