<ng-container *ngIf="moment$ | async as m">
  <ca-header
    [title]="m.title"
    [media]="m.image ? { image: { src: m.image.src, alt: m.image.alt } } : undefined"></ca-header>

  <div class="cheeses-list" *ngIf="cheeses.length > 0">
    <a *ngFor="let cheese of cheeses" [routerLink]="cheese.routerLink" class="cheeses-list-item">
      <img [class]="cheese.shape" [alt]="cheese.image.alt" [src]="base + cheese.image.src" />
      <div class="info">
        <h3>{{ cheese.name }}</h3>
        <span *ngIf="cheese.moments">
          <ng-container *ngFor="let m of cheese.moments">#{{ m.name }} </ng-container></span
        >
      </div>
    </a>
  </div>

  <div class="recipes-list" *ngIf="recipes.length > 0">
    <a *ngFor="let recipe of recipes" class="recipes-list-item" [routerLink]="recipe.routerLink">
      <img [alt]="recipe.image.alt" [src]="base + recipe.image.src" />
      <div class="info">
        <h4>
          <span *ngFor="let t of recipe.recipeTypes">{{ t.name }} </span>
        </h4>
        <h3>{{ recipe.name }}</h3>
        <div class="cooking-time" *ngIf="recipe.preparationTime > 0">
          <span class="material-symbols-outlined">schedule</span>
          <p>{{ recipe.preparationTime }} min</p>
        </div>
      </div>
    </a>
  </div>
</ng-container>
