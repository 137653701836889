import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  LoadCheeseDetail,
  LoadCheeseDetailSuccess,
  LoadCheesesListData,
  LoadedCheesesListData,
} from './cheeses.reducer';
import { ApiService } from '../services/api.service';
import { firstValueFrom, switchMap } from 'rxjs';
import { AppError } from './general.reducer';
import { CheeseDetailPageData, PageID } from '../models';

@Injectable()
export class CheesesEffects {
  load$ = this.actions$.pipe(ofType(LoadCheesesListData));
  loadDetail$ = this.actions$.pipe(ofType(LoadCheeseDetail));
  constructor(private actions$: Actions, private api: ApiService) {}

  onLoad$ = createEffect(() =>
    this.load$.pipe(
      switchMap(() =>
        firstValueFrom(this.api.getCheeses())
          .then((data) => LoadedCheesesListData({ data }))
          .catch((error) => AppError({ error }))
      )
    )
  );

  onLoadDetail$ = createEffect(() =>
    this.loadDetail$.pipe(
      switchMap(async ({ metaUrl }) => {
        try {
          if (!metaUrl) throw new Error('No metaUrl');
          const { data } = await firstValueFrom(
            this.api.getDetail<{ data: CheeseDetailPageData }>(PageID.CHEESES, metaUrl)
          );
          return LoadCheeseDetailSuccess({ data });
        } catch (error) {
          return AppError({ error });
        }
      })
    )
  );
}
