import * as CookieConsent from 'vanilla-cookieconsent';
import { ga4 } from './GA4';

/**
 * For options see: https://cookieconsent.orestbida.com/advanced/ui-customization.html
 */
export const UIOptions: CookieConsent.CookieConsentConfig['guiOptions'] = {
  consentModal: {
    layout: 'bar',
    position: 'bottom center',
    equalWeightButtons: true,
    flipButtons: false,
  },
  preferencesModal: {
    layout: 'box',
    position: 'right',
    equalWeightButtons: true,
    flipButtons: false,
  },
};

export const CookieBannerTranslations: CookieConsent.CookieConsentConfig['language']['translations'] =
  {
    en: 'assets/cookies-translations.en.json',
    nl: 'assets/cookies-translations.nl.json',
    fr: 'assets/cookies-translations.fr.json',
    es: 'assets/cookies-translations.es.json',
  };

export const CookieConsentConfig: CookieConsent.CookieConsentConfig = {
  guiOptions: UIOptions,
  categories: {
    necessary: {
      readOnly: true,
    },
    analytics: {
      enabled: false,
      autoClear: {
        cookies: [
          {
            name: /^_ga/, // regex: match all cookies starting with '_ga'
          },
          {
            name: '_gid', // string: exact cookie name
          },
        ],
      },
      services: {
        'Google Analytics': {
          label: 'Google Analytics',
          onAccept: () => {
            ga4.allowAnalytics();
          },
          onReject: () => {
            ga4.rejectAnalytics();
          },
          cookies: [
            {
              name: /^(_ga|_gid)/,
            },
          ],
        },
        youtube: {
          label: 'Youtube Embed',
          onAccept: () => {
            // TODO: handle accept
          },
          onReject: () => {
            // TOOD: disable youtube embeds
          },
        },
      },
    },
  },

  language: {
    default: 'nl',
    autoDetect: 'browser',
    translations: CookieBannerTranslations,
  },
};
// TODO: replace when analytics cookies are disabled
export const YOUTUBE_NO_COOKIE_DOMAIN = 'www.youtube-nocookie.com';
export const YOUTUBE_COOKIE_DOMAINS = [
  'www.youtube.com',
  'youtube.com',
  'www.youtu.be',
  'youtu.be',
];
