<ca-header *ngIf="banner" [media]="banner"></ca-header>

<div class="mixed" *ngIf="title">
  <section class="intro">
    <h1 *ngIf="title">{{ title }}</h1>
    <div *ngIf="text" [innerHTML]="text"></div>
  </section>

  <section class="mixed" *ngIf="highlightedItems">
    <a
      *ngFor="let i of highlightedItems"
      [class]="'home-block ' + i.type"
      [routerLink]="i.routerLink">
      <img
        *ngIf="i.type === 'cheese'"
        [class]="i.shape"
        [alt]="i.image.alt"
        [src]="mediaUrl + i.image.src" />
      <img *ngIf="i.type === 'moment'" [alt]="i.image.alt" [src]="mediaUrl + i.image.src" />
    </a>
  </section>
</div>
