import { createAction, createReducer, on, props } from '@ngrx/store';
import { SustainabilityPageData } from '../models';
import { SetLanguage } from './general.reducer';

export interface SustainabilityState {
  data?: SustainabilityPageData;
}

export const initialState: SustainabilityState = {};

//#region Actions
export enum SustainabilityActions {
  load = '[Sustainability] load',
  loaded = '[Sustainability] loaded',
}

export const LoadSustainability = createAction(SustainabilityActions.load);
export const LoadedSustainability = createAction(
  SustainabilityActions.loaded,
  props<{ data: SustainabilityPageData }>()
);
//#endregion

export const reducer = createReducer(
  initialState,
  on(LoadedSustainability, (_, { data }) => ({ data })),
  on(SetLanguage, () => initialState)
);
