import { createAction, createReducer, on, props } from '@ngrx/store';
import { SetLanguage } from './general.reducer';
import { PageDataBase } from '../models';

export const initialState: PageDataBase = {
  title: 'Privacy and Cookies',
  text: '',
  seo: {
    description: '',
    title: '',
    image: undefined,
  },
};

export enum PrivacyPolicyActions {
  load = '[Privacy Policy] load',
  loaded = '[Privacy Policy] loaded',
}
export const LoadPrivacyPolicy = createAction(PrivacyPolicyActions.load);
export const LoadedPrivacyPolicy = createAction(
  PrivacyPolicyActions.loaded,
  props<{ data: PageDataBase }>()
);

export const reducer = createReducer(
  initialState,
  on(LoadedPrivacyPolicy, (_, { data }) => data),
  on(SetLanguage, () => initialState)
);
